import { listAllPrinter } from '@/api/system/printTemplate'
import { listCategoryByParentId } from '@/api/product/category'
import { getTenantDictList } from '@/api/system/tenantDictionary'
import { State } from '@/enums'
import { isDef } from '@/utils/validate'
import { treePermission } from '@/api/user/role'

function initMapCache() {
	return {
		filter: [],
		source: [],
		map: {}
	}
}

const minute = 60000
const fiveMinute = minute * 5
const fifteenMinute = fiveMinute * 3
export const CacheKey = {
	Permissions: 'permissions'
}
export default {
	namespaced: true,
	state: {
		/**
		 * @type Core.AnyNumber
		 */
		lastReq: {
			bigClassCode: 0,
			classCode: 0,
			operationSequence: 0,
			unit: 0,
			storageMode: 0,

			printTemplate: 0,

			rootCategories: 0,
			[CacheKey.Permissions]: 0
		},
		/**
		 * @type Core.AnyNumber
		 */
		interval: {
			bigClassCode: fifteenMinute,
			classCode: fifteenMinute,
			operationSequence: fifteenMinute,
			unit: fifteenMinute,
			storageMode: fifteenMinute,

			printTemplate: fifteenMinute,
			rootCategories: fifteenMinute,
			[CacheKey.Permissions]: fiveMinute
		},
		cache: {
			/**
			 * @deprecated
			 */
			bigClassCode: initMapCache(),
			/**
			 * @deprecated
			 */
			classCode: initMapCache(),
			rootCategories: initMapCache(),
			operationSequence: initMapCache(),
			unit: initMapCache(),
			storageMode: initMapCache(),
			printTemplate: [],
			permissions: []
		}
	},
	actions: {
		/**
		 *
		 * @param commit
		 * @param dispatch
		 * @param state
		 * @param rootGetters
		 * @param {boolean} force
		 * @return {Promise<*|*[]>}
		 */
		async UpdateRootCategory({ commit, dispatch, state, rootGetters }, force) {
			const now = Date.now()
			const key = 'rootCategories'
			const { value, interval, lastReq } = await dispatch('GetCacheByKey', key)
			const source = value?.source || []
			if (force || source.length === 0 || now - interval >= lastReq) {
				const { data: value } = await listCategoryByParentId(0)
				commit('SET_MAP_CACHE', {
					key,
					value
				})
				commit('SET_LAST_REQ', { key, value: now })
				return value
			}
			return source
		},
		async UpdateOperationSequence({ commit, dispatch, state, rootGetters }, force) {
			const now = Date.now()
			const key = 'operationSequence'
			const { value, interval, lastReq } = await dispatch('GetCacheByKey', key)
			const source = value?.source || []

			if (force || source.length === 0 || now - interval >= lastReq) {
				const { data: value } = await getTenantDictList(key)
				commit('SET_MAP_CACHE', {
					key,
					value
				})
				commit('SET_LAST_REQ', { key, value: now })
				return value
			}
			return source
		},
		async UpdateUnit({ commit, dispatch, state, rootGetters }, force) {
			const now = Date.now()
			const key = 'unit'
			const { value, interval, lastReq } = await dispatch('GetCacheByKey', key)
			const source = value?.source || []

			if (force || source.length === 0 || now - interval >= lastReq) {
				const { data: value } = await getTenantDictList(key)
				commit('SET_MAP_CACHE', {
					key,
					value
				})
				commit('SET_LAST_REQ', { key, value: now })
				return value
			}
			return source
		},
		async UpdateStorageMode({ commit, dispatch, state, rootGetters }, force) {
			const now = Date.now()
			const key = 'storageMode'
			const { value, interval, lastReq } = await dispatch('GetCacheByKey', key)
			const source = value?.source || []

			if (force || source.length === 0 || now - interval >= lastReq) {
				const { data: value } = await getTenantDictList(key)
				commit('SET_MAP_CACHE', {
					key,
					value
				})
				commit('SET_LAST_REQ', { key, value: now })
				return value
			}
			return source
		},

		/**
		 * @param commit
		 * @param dispatch
		 * @param {boolean?} force
		 * @return {Promise<Tenant.SimpleRow[]|{length}|*>}
		 */
		async UpdatePrintTemplate({ commit, dispatch }, force) {
			const now = Date.now()
			const key = 'printTemplate'

			const { value, interval, lastReq } = await dispatch('GetCacheByKey', key)
			//间隔查询
			if (force || !value.length || now - interval >= lastReq) {
				const { data } = await listAllPrinter()
				commit('SET_CACHE', {
					key,
					value: data
				})
				commit('SET_LAST_REQ', { key, value: now })
				return data
			}

			return value
		},
		async UpdatePermissions({ commit, dispatch }, force) {
			return Promise.resolve()
			const now = Date.now()
			const { value, interval, lastReq } = await dispatch('GetCacheByKey', CacheKey.Permissions)
			console.log(value, interval, lastReq)
			//间隔查询
			if (force || !value.length || now - interval >= lastReq) {
				const { data } = await treePermission()
				commit('SET_CACHE', {
					key: CacheKey.Permissions,
					value: data
				})
				commit('SET_LAST_REQ', { key: CacheKey.Permissions, value: now })
				return data
			}

			return value
		},
		/**
		 * @param state
		 * @param {string}key
		 * @return {Promise<{lastReq: number, interval: number, value: *, key:string}>}
		 */
		async GetCacheByKey({ state }, key) {
			const value = state.cache[key]
			const interval = state.interval[key]
			const lastReq = state.lastReq[key]
			return {
				key,
				value,
				interval,
				lastReq
			}
		},
		Recovery({ commit }) {
			commit('RECOVERY')
		},
		SetLastReq({ commit }, { key, value }) {
			commit('SET_LAST_REQ', {
				key,
				value
			})
		}
	},
	mutations: {
		SET_CACHE(state, { key, value }) {
			state.cache = {
				...state.cache,
				[`${key}`]: value
			}
		},
		SET_MAP_CACHE(state, { key, value }) {
			const map = {}
			value.forEach((v, k) => {
				map[v.code] = v
			})
			state.cache = {
				...state.cache,
				[`${key}`]: {
					source: value,
					filter: value.filter((v) => (isDef(v.state) ? v.state === State.Valid : true)),
					map
				}
			}
		},
		SET_LAST_REQ(state, { key, value }) {
			state.lastReq = {
				...state.lastReq,
				[`${key}`]: value
			}
		},
		RECOVERY(state) {
			const lastReq = {}
			Object.keys(state.lastReq).forEach((key) => {
				lastReq[key] = 0
			})
			state.lastReq = lastReq
		}
	},
	getters: {
		printTemplate: (state) => state.cache.printTemplate
	}
}
