<template>
	<section class="app-main">
		<div class="app-main-content">
      <transition name="fade-transform" mode="out-in">
        <keep-alive :include="cachedViews">
          <router-view />
        </keep-alive>
      </transition>
    </div>
		<el-footer class="app-main-footer">
			<Copyright />
		</el-footer>
	</section>
</template>

<script>
import Copyright from '@/components/Copyright/Copyright.vue'

export default {
	name: 'AppMain',
	components: { Copyright },
	computed: {
		cachedViews() {
			return this.$store.state.tagsView.cachedViews
		},
		key() {
			return this.$route.path
		}
	},
	mounted() {}
}
</script>

<style lang="scss" scoped>
.app-main {
	/* 50= navbar  50  */
	min-height: calc(100vh - 50px);
	width: 100%;
	position: relative;
	overflow: hidden;
	display: flex;
	flex-direction: column;

	&-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

  }
	&-footer {
		color: #888;
		text-align: center;
		line-height: 24px;

		p {
			font: 12px/1.5 'microsoft yahei', Helvetica, Tahoma, Arial, 'Microsoft jhengHei', sans-serif;
			color: #888;
			line-height: 24px;
			margin: 0;
		}
	}
}

.fixed-header + .app-main {
	padding-top: 50px;
}

.hasTagsView {
	.app-main {
		/* 84 = navbar + tags-view = 50 + 34 */
		min-height: calc(100vh - 84px);
	}

	.fixed-header + .app-main {
		padding-top: 84px;
	}
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
	.fixed-header {
		padding-right: 15px;
	}
}
</style>
