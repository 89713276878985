export default [
  {
    name: 'SubscriptionManagement',
    path: '/subscription',
    hidden: true,
    redirect: {
      name: 'SubscriptionManagementList'
    },
    component: 'Layout',
    alwaysShow: true,
    meta: {
      title: '订阅管理',
      icon: 'subscription',
      noCache: false,
      link: null
    },
    children: [
      {
        name: 'SubscriptionManagementList',
        path: 'list',
        hidden: false,
        component: 'SubscriptionManagement/list/index',
        meta: {
          title: '订阅记录',
          icon: 'time-range',
          noCache: false,
          link: null
        }
      }
    ]
  },
  {
    name: 'ProductManagement',
    path: '/productManagement',
    hidden: false,
    redirect: {
      name: 'ProductManagement-Item'
    },
    component: 'Layout',
    alwaysShow: true,
    meta: {
      title: '品项管理',
      icon: 'ProductManagement',
      noCache: false,
      link: null,
      permissions: ['product:category:list', 'product:product:list']
    },
    children: [
      {
        name: 'ProductManagement-Category',
        path: 'category',
        hidden: false,
        component: 'ProductManagement/category/index',
        meta: {
          title: '类别维护',
          icon: 'filter',
          noCache: false,
          link: null,
          permissions: 'product:category:list'
        }
      },
      {
        name: 'ProductManagement-Item',
        path: 'item',
        hidden: false,
        component: 'ProductManagement/item/index',
        meta: {
          title: '品项维护',
          icon: 'layers',
          noCache: false,
          link: null,
          permissions: 'product:product:list'
        }
      }
    ]
  },
  {
    name: 'LabelManagement',
    path: '/labelManagement',
    hidden: false,
    redirect: {
      name: 'LabelManagement-List'
    },
    component: 'Layout',
    alwaysShow: true,
    meta: {
      title: '标签管理',
      icon: 'LabelManagement',
      noCache: false,
      link: null,
      permissions: ['label:expire:list', 'label:label:list']
    },
    children: [
      {
        name: 'LabelManagement-Alert',
        path: 'alert',
        hidden: false,
        component: 'LabelManagement/alert/index',
        meta: {
          title: '过期预警',
          icon: 'LabelManagement-Alert',
          noCache: false,
          link: null,
          permissions: 'label:expire:list'
        }
      },
      {
        name: 'LabelManagement-List',
        path: 'list',
        hidden: false,
        component: 'LabelManagement/list/index',
        meta: {
          title: '标签列表',
          icon: 'list',
          noCache: false,
          link: null,
          permissions: 'label:label:list'
        }
      },
      {
        name: 'ExpirationManagement-Tag',
        path: 'tag',
        hidden: false,
        component: 'ExpirationManagement/tag/index',
        meta: {
          title: '标签制作',
          icon: 'ExpirationManagement-Tag',
          noCache: false,
          link: null,
          permissions: 'label:printer:list'
        }
      }
    ]
  },
  {
    name: 'EnterpriseManagement',
    path: '/enterpriseManagement',
    hidden: false,
    redirect: {
      name: 'EnterpriseManagement-Profile'
    },
    component: 'Layout',
    alwaysShow: true,
    meta: {
      title: '企业管理',
      icon: 'EnterpriseManager',
      noCache: false,
      link: null,
      permissions: ['enterprise:enterprise:list', 'enterprise:store:list', 'enterprise:department:list']
    },
    children: [
      {
        name: 'EnterpriseManagement-Profile',
        path: 'profile',
        hidden: false,
        component: 'EnterpriseManagement/profile/index',
        meta: {
          title: '企业信息',
          icon: 'EnterpriseInfo',
          noCache: false,
          link: null,
          permissions: 'enterprise:enterprise:list'
        }
      },
      {
        name: 'EnterpriseManagement-Store',
        path: 'store',
        hidden: false,
        component: 'EnterpriseManagement/store/index',
        meta: {
          title: '门店管理',
          icon: 'EnterpriseStore',
          noCache: false,
          link: null,
          permissions: 'enterprise:store:list'
        }
      },
      {
        name: 'EnterpriseManagement-Dept',
        path: 'dept',
        hidden: false,
        component: 'EnterpriseManagement/dept/index',
        meta: {
          title: '部门管理',
          icon: 'EnterpriseDept',
          noCache: false,
          link: null,
          permissions: 'enterprise:department:list'
        }
      }
    ]
  },
  {
    name: 'UserManagement',
    path: '/userManagement',
    hidden: false,
    redirect: 'noRedirect',
    component: 'Layout',
    alwaysShow: true,
    meta: {
      title: '用户管理',
      icon: 'UserManagement',
      noCache: false,
      link: null,
      permissions: ['user:user:list', 'user:role:list']
    },
    children: [
      {
        name: 'UserManagement-List',
        path: 'list',
        hidden: false,
        component: 'UserManagement/list/index',
        meta: {
          title: '用户列表',
          icon: 'UserManagement-List',
          noCache: false,
          link: null,
          permissions: 'user:user:list'
        }
      },
      {
        name: 'UserManagement-Role',
        path: 'role',
        hidden: false,
        component: 'UserManagement/role/index',
        meta: {
          title: '角色维护',
          icon: 'UserManagement-Role',
          noCache: false,
          link: null,
          permissions: 'user:role:list'
        }
      }
    ]
  },
  {
    name: 'SystemManagement',
    path: '/systemManagement',
    hidden: false,
    redirect: 'noRedirect',
    component: 'Layout',
    alwaysShow: true,
    meta: {
      title: '系统管理',
      icon: 'system',
      noCache: false,
      link: null,
      permissions: ['system:operation-sequence:list', 'system:storage-mode:list', 'system:unit:list', 'system:task:list', 'system:setting:list']
    },
    children: [
      {
        name: 'DictionaryManagement',
        path: 'dictionaryManagement',
        hidden: true,
        component: 'SystemManagement/DictionaryManagement/index',
        meta: {
          title: '字典管理',
          icon: 'dict',
          noCache: false,
          link: null
        }
      },
      {
        name: 'SystemManagement-OperationSequence',
        path: 'operationSequence',
        hidden: false,
        component: 'SystemManagement/operationSequence',
        meta: {
          title: '操作步骤',
          icon: 'dict',
          noCache: false,
          link: null,
          permissions: 'system:operation-sequence:list'
        }
      },
      {
        name: 'SystemManagement-StorageMode',
        path: 'storageMode',
        hidden: false,
        component: 'SystemManagement/storageMode',
        meta: {
          title: '存储方式',
          icon: 'dict',
          noCache: false,
          link: null,
          permissions: 'system:storage-mode:list'
        }
      },
      {
        name: 'SystemManagement-Unit',
        path: 'unit',
        hidden: false,
        component: 'SystemManagement/unit',
        meta: {
          title: '品项单位',
          icon: 'dict',
          noCache: false,
          link: null,
          permissions: 'system:unit:list'
        }
      },
      {
        name: 'SystemManagement-Task',
        path: 'task',
        hidden: false,
        component: 'SystemManagement/task',
        meta: {
          title: '任务列表',
          icon: 'download',
          noCache: false,
          link: null,
          permissions: 'system:task:list'
        }
      },
      {
        name: 'SystemManagement-Setting',
        path: 'setting',
        hidden: false,
        component: 'SystemManagement/setting',
        meta: {
          title: '系统设置',
          icon: 'system',
          noCache: false,
          link: null,
          permissions: 'system:setting:list'
        }
      }
    ]
  }
]
